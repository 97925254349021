import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-free-therm-modal',
  templateUrl: './free-therm-modal.component.html',
  styleUrls: ['./free-therm-modal.component.scss'],
  standalone: false,
})
export class FreeThermModalComponent {
  @Input()
  api!: {
    select: (b: boolean) => Promise<boolean>
  }
}
